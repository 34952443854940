/* eslint-disable camelcase */
import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';
import { pageLinks } from '../../helpers/navigation';
import {
  isClient,
  getInstanceName,
  isIbiInstances,
  normalizeSocialLinks,
  instanceList,
} from '../../helpers/utils';
import styles from './footer.module.scss';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { getCountryData } from '../../helpers/countries';
import checkMobile from '../../hooks/checkMobile';
import { socialLinksWhite } from '../banner-email-check/constants';

const stateSelector = createStructuredSelector({
  userDetails: selectEntityUserDetails,
});

const query = graphql`
  query {
    amex: file(relativePath: { eq: "cards/amex.png" }) {
      childImageSharp {
        fixed(width: 75, height: 26) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    visa: file(relativePath: { eq: "cards/visa.png" }) {
      childImageSharp {
        fixed(width: 65, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mastercard: file(relativePath: { eq: "cards/mastercard.png" }) {
      childImageSharp {
        fixed(width: 50, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prismic {
      allFooters {
        edges {
          node {
            onlineline {
              address
              address_as_image
            }
            ibiusa {
              address
              address_as_image
              youtube {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              twitter {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              tiktok {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              instagram {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            ibiusatwo {
              address
              address_as_image
            }
            ibiworldtwo {
              address
              address_as_image
            }
          }
        }
      }
    }
  }
`;

const aboutUsLinks = [
  {
    id: 0,
    name: 'promote',
    url: pageLinks.promote,
  },
  {
    id: 1,
    name: 'about_us',
    url: pageLinks.aboutUs,
  },
];

const policyLinks = [
  {
    id: 0,
    name: 'privacy_policy',
    url: pageLinks.privacy,
  },
  {
    id: 12,
    name: 'aml_policy',
    url: pageLinks.amlPolicy,
  },
  {
    id: 1,
    name: 'gdpr_policy',
    url: pageLinks.gdpr,
  },
  {
    id: 2,
    name: 'terms_of_use',
    url: pageLinks.terms,
  },
  {
    id: 3,
    name: 'refund_policy',
    url: pageLinks.refund,
  },
  {
    id: 4,
    name: 'IBIAgreement',
    url: pageLinks.ibi,
  },
  {
    id: 10,
    name: 'compensation_plan',
    url: pageLinks.compensationPlan,
  },
  {
    id: 11,
    name: 'policies_and_procedures',
    url: pageLinks.policiesProcedures,
  },
  {
    id: 7,
    name: 'education_videos',
    url: pageLinks.eduVideos,
  },
  {
    id: 5,
    name: 'earning_statement',
    url: pageLinks.earningDisclosure,
  },
  {
    id: 9,
    name: 'spam_policy',
    url: pageLinks.spam,
  },
  {
    id: 6,
    name: 'code_ethics',
    url: pageLinks.codeEthics,
  },

  {
    id: 8,
    name: 'cookies_policy',
    url: pageLinks.cookiePolicy,
  },
];

const Footer = () => {
  const intl = useIntl();
  const isMobile = checkMobile();
  let addressImage = null;
  const data = useStaticQuery(query);
  let socials;

  const { userDetails } = useSelector(stateSelector);
  const siteInstance = isIbiInstances ? instanceList.ibi : process.env.GATSBY_INSTANCE_NAME

  if (isIbiInstances) {
    const prismicData =
      data?.prismic?.allFooters?.edges[0].node[siteInstance][0];
    socials = normalizeSocialLinks(socialLinksWhite, prismicData);
  }

  if (data && data.prismic) {
    const instance = (siteInstance || 'onlineLine').toLowerCase();
    addressImage =
      data.prismic.allFooters.edges[0].node[instance][0].address_as_image;
  }

  const currentYear = new Date().getFullYear();
  const languageWithLargeWords = () => isClient && window.localStorage.getItem('wglang') === 'de';
  const siteName = isClient && getInstanceName();

  const renderAddress = () => addressImage ? (
      <div className={styles.addressImageContainer}>
        <img
          className={clsx(styles.addressImg, 'address-img')}
          src={isMobile ? addressImage?.address_as_image_mob?.url : addressImage?.url}
          alt="address"
        />
      </div>
    ) : null
  ;

  function createDocLink(docPath) {
    const countryCode = userDetails && userDetails.country;
    const countryData = getCountryData(countryCode);
    const countryName = countryData ? countryData.name : null;
    if (!countryName) {
      return docPath;
    }
    const searchParams = new URLSearchParams(`country=${countryName}`);
    return `${docPath}?${searchParams.toString()}`;
  }

  return (
    <footer className={clsx(styles.footer)}>
      <div className="container">
        <div className={clsx(styles.footer_content_wrapper)}>
          <div className={clsx(styles.about_us_container)}>
            {aboutUsLinks.map((link) => (
              <Link key={link.id} to={link.url} className={clsx(styles.footer_link__main)}>
                {_.upperCase(intl.formatMessage({ id: link.name }))}
              </Link>
            ))}
          </div>
          <div className={styles.legal_container}>
            <h4>LEGAL</h4>
            <div
              className={clsx(
                styles.policy_wrapper,
                languageWithLargeWords && styles.policy_wrapper_column
              )}
            >
              {policyLinks.map((x) => (
                <a
                  key={x.id}
                  href={createDocLink(x.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={clsx(styles.policy_link)}
                >
                  {_.upperCase(intl.formatMessage({ id: x.name }))}
                </a>
              ))}
            </div>
          </div>
          {isIbiInstances && addressImage && (
            <div className={styles.address_container}>
              <h4>ADDRESS</h4>
              {renderAddress()}
            </div>
          )}
        </div>
        <div className={styles.additional_wrapper}>
          <div>
            <form action="" className={styles.form} />
          </div>
        </div>

        <div className={styles.copyRightContainer}>
          {isIbiInstances && (
            <ul className={styles.footer_socials}>
              {socials?.map((icon) => (
                <li key={icon.id} className={styles.iconLi}>
                  <a href={icon.url} title={icon.name} target="_blank" rel="noopener noreferrer">
                    <div className={styles.icon}>{icon.src}</div>
                  </a>
                </li>
              ))}
            </ul>
          )}

          <div className={clsx(styles.reserved)}>
            <span>
              {siteName}
              <sup>TM</sup>
            </span>
            <span>{currentYear}</span>
            <span>{intl.formatMessage({ id: 'all_rights_reserved' })}</span>
            <span>©</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
