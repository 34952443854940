/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';

import styles from './inputSelect.module.scss';

const InputSelect = (props) => {
  const {
    options,
    label,
    labelClassName,
    id,
    placeholder,
    onChange,
    className,
    compactSize,
    defaultValue,
    invertDark,
    required,
    errorMessage,
    menuPlacement,
    languageSelect,
  } = props;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: compactSize ? 30 : 52,
      minHeight: compactSize ? 30 : 52,
      borderWidth: compactSize ? 0 : 1,
      borderColor: errorMessage ? '#e10f54 !important' : '#96929a !important',
      borderRadius: 3,
      boxShadow: 'none',
      paddingLeft: compactSize ? 0 : 16,

      backgroundColor: 'transparent',

      '&:hover': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    }),

    indicatorSeparator: () => ({}),

    indicatorsContainer: (provided) => ({
      ...provided,
      display: compactSize ? 'none' : 'block',
      padding: 7,
      paddingRight: compactSize || languageSelect ? 0 : 10,
      svg: { fill: '#303030' },
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: '100%',
      padding: 0,
      fontSize: 15,
      outline: 'none',
      color: invertDark ? '#fff' : '#11031c',
    }),

    placeholder: (provided) => ({
      ...provided,
      color: '#5e5e5e',
    }),

    menu: () => ({
      position: 'absolute',
      [menuPlacement]: '100%',
      left: 0,
      width: languageSelect ? '90px' : '100%',
      marginLeft: languageSelect ? -4 : 'auto',
      padding: 0,
      border: '1px solid #96929a',
      borderTopWidth: compactSize ? 1 : 0,

      borderTopLeftRadius: compactSize ? 3 : 0,
      borderTopRightRadius: compactSize ? 3 : 0,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,

      overflow: 'hidden',
      zIndex: 4,
    }),

    menuList: () => ({ backgroundColor: '#fff' }),

    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: compactSize ? 5 : 20,
      height: compactSize ? 30 : 50,
      cursor: 'pointer',
      transition: '.15s ease-in-out',
      color: '#11031c',

      '&:hover': { backgroundColor: '#f7f7f7', color: '#11031c' },
    }),

    singleValue: (provided) => ({
      ...provided,
      color: invertDark ? '#fff' : '#11031c',
    }),
  };

  const rest = defaultValue ? { value: defaultValue } : {};

  return (
    <div className={clsx(compactSize ? null : styles.wrapper, styles.select, className)}>
      {label ? (
        <label className={clsx('basicLabel', labelClassName)} htmlFor={id}>
          {label}
          {required && (
            <span aria-hidden className={styles.labelAsterisk}>
              *
            </span>
          )}
        </label>
      ) : null}

      <Select
        id={id}
        options={options}
        className={clsx(styles.selectContainer)}
        styles={customStyles}
        placeholder={placeholder}
        onChange={onChange}
        isSearchable={false}
        {...rest}
      />
      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
    </div>
  );
};

InputSelect.defaultProps = {
  label: null,
  labelClassName: null,
  placeholder: '',
  className: '',
  compactSize: false,
  value: null,
  invertDark: null,
  required: false,
  errorMessage: '',
  defaultValue: null,
  menuPlacement: '',
  languageSelect: false,
};

InputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  compactSize: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
  }),
  invertDark: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  defaultValue: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    label: PropTypes.node.isRequired,
  }),
  menuPlacement: PropTypes.string,
  languageSelect: PropTypes.bool,
};

export default InputSelect;
