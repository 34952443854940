import React from 'react';

import Instagram from '../../icons/svg/instagram.svg';
import TikTok from '../../icons/svg/tiktok.svg';
import WhatsApp from '../../icons/svg/whatsapp.svg';
import InstagramWhite from '../../icons/svg/instagram-white.svg';
import TikTokWhite from '../../icons/svg/tiktok-white.svg';
import TwitterWhite from '../../icons/svg/twitter-white.svg';
import FacebookWhite from '../../icons/svg/facebook-white.svg';
import YoutubeWhite from '../../icons/svg/youtube-white.svg';

export const socialLinks = [
  {
    id: 0,
    name: 'Instagram',
    url: '',
    src: <Instagram />,
  },
  {
    id: 1,
    name: 'TikTok',
    url: '',
    src: <TikTok />,
  },
  {
    id: 2,
    name: 'WhatsApp',
    url: '',
    src: <WhatsApp />,
  },
];


export const socialLinksWhite = [
  {
    id: 0,
    name: 'facebook',
    url: '',
    src: <FacebookWhite />,
  },
  {
    id: 1,
    name: 'youtube',
    url: '',
    src: <YoutubeWhite />,
  },
  {
    id: 2,
    name: 'instagram',
    url: '',
    src: <InstagramWhite />,
  },
  {
    id: 3,
    name: 'tiktok',
    url: '',
    src: <TikTokWhite />,
  },
  {
    id: 4,
    name: 'twitter',
    url: '',
    src: <TwitterWhite />,
  },
];
