import clsx from 'clsx';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import InputSelect from '../inputSelect';
import { isClient, setLanguageToLocalStorage } from '../../helpers/utils';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { selectChozenLanguage } from '../../store/selectors/global';
import { changeLanguage } from '../../store/actions';

import styles from './language-select.module.scss';
import { useStorage } from '../../services/storage';

const getFlag = (code) => {
  let currentCode;

  switch (code) {
    case 'en':
      currentCode = 'gb';
      break;
    case 'zh':
      currentCode = 'cn';
      break;
    case 'ja':
      currentCode = 'jp';
      break;
    case 'ko':
      currentCode = 'kr';
      break;
    default:
      currentCode = code;
  }

  return <Flag code={currentCode} width="32" height="16" />;
};

const languageOptions = (languages = ['en']) => {
  // in case languages === null
  if (!languages) {
    return [];
  }

  return languages.map((code) => ({
    value: code,
    label: (
      <div className="inputFlagSelect">
        {getFlag(code)}
        <span>{code}</span>
      </div>
    ),
  }));
};

const stateSelector = createStructuredSelector({ userDetails: selectEntityUserDetails });

const LanguageSelect = ({ id, languages, className, invertDark, menuPlacement }) => {
  const { userDetails } = useSelector(stateSelector);
  const selectedLanguage = useSelector(selectChozenLanguage);

  const dispatch = useDispatch();

  const registeredLang = userDetails?.languageId;

  const options = languageOptions(languages);

  useEffect(() => {
    const languageCurrent = isClient
      ? window?.Weglot?.initialized && window?.Weglot?.getCurrentLang()
      : 'en';

    const selectedOption = options.find((option) => option.value === languageCurrent);

    if (isClient) {
      const wglang = useStorage().getItem('wglang');
      if (registeredLang && !wglang && window?.Weglot?.initialized) {
        const { getCurrentLang, switchTo } = window.Weglot;
        if (getCurrentLang() !== registeredLang) {
          switchTo(registeredLang);

          dispatch(changeLanguage(registeredLang));
          setLanguageToLocalStorage(registeredLang);
        }
      }
    }

    if (selectedOption && selectedLanguage !== selectedOption.value) {
      setLanguageToLocalStorage(selectedOption.value);
      dispatch(changeLanguage(selectedOption.value));
    }

    if (registeredLang) {
      dispatch(changeLanguage(registeredLang));
      setLanguageToLocalStorage(registeredLang);
    }
  }, [languages, registeredLang]);

  const onLanguageChange = (option) => {
    if (isClient) {
      window.Weglot.switchTo(option.value);
    }
    dispatch(changeLanguage(option.value));
    setLanguageToLocalStorage(option.value);
  };

  const value = options.find((item) => item.value === selectedLanguage);

  return (
    <div className={styles.inputSelectWrapper}>
      <InputSelect
        compactSize
        id={id}
        invertDark={invertDark}
        options={options}
        menuPlacement={menuPlacement}
        onChange={onLanguageChange}
        className={clsx(styles.wrapper, className)}
        defaultValue={value}
        languageSelect
        value={value}
      />

      <i className="icon-arrow-down" />
    </div>
  );
};

LanguageSelect.defaultProps = {
  languages: [],
  className: null,
  invertDark: null,
  menuPlacement: '',
};

LanguageSelect.propTypes = {
  id: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  invertDark: PropTypes.bool,
  menuPlacement: PropTypes.string,
};

export default LanguageSelect;
