import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './popover.module.scss';

const Popover = ({ hoverText }) => (
    <div className={clsx(styles.helper)}>
      <span>?</span>
      {hoverText && <span className={clsx(styles.helperText)}>{hoverText}</span>}
    </div>
  );

Popover.propTypes = {
  hoverText: PropTypes.string.isRequired,
};

export default Popover;
