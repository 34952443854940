import React, { useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';
import validateEmail from '../../services/api/actions/validateEmail';
import changeEmail from '../../services/api/actions/changeEmail';
import { toggleEmailCheckBanner } from '../../store/actions';
import Input from '../input';
import Button from '../button';
import setNotification from '../../helpers/notifications';
import styles from './banner-email-check.module.scss';

const BannerEmailCheck = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const email = intl.formatMessage({ id: 'email' });
  const [emailValue, changeEmailValue] = useState('');
  const [errorMessage, setError] = useState('');

  const closeModal = () => {
    dispatch(toggleEmailCheckBanner(false));
  };

  const validateDeep = async () => {
    const response = await dispatch(validateEmail.withQuery(`?email=${emailValue}`).action());
    const { payload, error } = response;
    if (error) {
      setError('email address is not verified, please try another one.');
      return;
    }
    const changeEmailResponse = await dispatch(changeEmail.action({ email: payload.data.email }));

    if (changeEmailResponse.payload) {
      setNotification('success', {
        message: 'New address saved',
        title: 'Success',
      });
      closeModal();
      return;
    }
    if (changeEmailResponse.error) {
      setError(changeEmailResponse.error.response.data.message);
    }
  };

  const renderVerifyStep = () => (
    <>
      <div className={styles.headerInfo}>
        <span>We have noticed you have invalid email address.</span>
        <span>Please enter your new valid email.</span>
      </div>
      <Input
        id="email"
        label={email}
        placeholder={email}
        onChange={(e) => {
          changeEmailValue(e.target.value);
          setError('');
        }}
        value={emailValue}
        fullwidth
        type="email"
        required
        errorMessage={errorMessage}
      />

      <div className={clsx(styles.buttonWrapper)}>
        <Button
          withArrow
          color="primary"
          size="large"
          fullWidth
          type="submit"
          disabled={!emailValue.length}
          className={clsx(styles.submitButton)}
          onClick={validateDeep}
        >
          Save e-mail
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles.overlay}>
      <div className={styles.bannerWrapper}>
        <div className={styles.modalTitle}>
          <button type="button" className={styles.close} onClick={closeModal}>
            <i className="icon-close" />
          </button>
        </div>
        <div className={styles.bannerContainer}>{renderVerifyStep()}</div>
      </div>
    </div>
  );
};

export default BannerEmailCheck;
